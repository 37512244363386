import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Card } from '../../components/library'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    card: {
        width: 300,
        height: 300
    },
    glass: {
        position: 'fixed',
        top: 80,
        left: '20%'
    }
}));

function card() {
    const classes = useStyles();
    return (
        <LibraryWrapper>
            <Card className={classes.card} />
            <Card className={classes.card} bg="purple" />
            <Card className={classes.card} bg="purple-light" />
            <Card className={classes.card} bg="purple-lighter" />
            <Card className={`${classes.card} ${classes.glass}`} bg="glass" />
        </LibraryWrapper>
    )
}
export default card
